<template>
  <div style="display: flex">
    <click-to-edit
      v-model="scope.row.scale.type"
      style="flex: 1 1 auto; margin-right: 5px; overflow: hidden; overflow-wrap: anywhere"
      placeholder="Выберите тип цели"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.type')"
      type="select"
      select-filterable
      :select-options="scaleTypes"
      select-option-id="id"
      select-option-name="name"
      select-cast-value-to-int
      @input="saveElementPropChange(scope.row, 'scale', {type:$event})"
    >
    </click-to-edit>
    <el-popover
      placement="top"
      title="Описание"
      width="400"
      trigger="click"
    >
      <el-button
        slot="reference"
        class="table-action-button open-desc-button"
        type="text"
        round
        size="mini"
        icon="fas fa-info-circle"
      ></el-button>
      <div style="white-space: pre-line">{{scaleDesc}}</div>
    </el-popover>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";

export default {
  name: 'element-scale-type-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {
    scaleDesc() {
      const currentScale = this.scaleTypes.find(s => s.id === this.scope.row.scale.type);
      if( !currentScale ){
        return 'Необходимо сначала выбрать шкалу';
      }
      return currentScale.desc;
    }
  },
  data() {
    return {
      scaleTypes: variables.scaleTypes
    }
  },

  methods: {}
}

</script>

<style>

</style>
